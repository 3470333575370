<template>
  <div class="share-text">
      text
  </div>
</template>

<script>
export default {
    name:'shareText'
}
</script>

<style>

</style>